<template>
  <div class="gaisai" id="gaisai">
    <Nav/>
      <main id="main" role="main">
          <section>
            <h2><img src="@/assets/img/head_gaisai.svg" alt="外祭・出張祭典・神葬祭"></h2>
            <div class="box">
              <ul class="square">
                <li>初穂料 20,000円〜</li>
                <li>準備や内容で初穂料が変わります。ご相談ください。</li>
              </ul>
            </div>
            <div class="box">
              <ul class="square">
                <li><p>地鎮祭</p></li>
                <li><p>上棟祭</p></li>
                <li><p>竣工祭</p></li>
                <li><p>安全祈願</p></li>
                <li><p>家清祓い祭</p></li>
                <li><p>神棚魂入祭</p></li>
                <li><p>神棚清祓い祭</p></li>
                <li><p>土地清祓い祭</p></li>
                <li><p>井戸清祓い祭</p></li>
                <li><p>伐採清祓い祭</p></li>
                <li><p>開店清祓い祭</p></li>
                <li><p>清祓い祭</p></li>
                <li><p>神葬祭</p></li>
              </ul>
              <p>など、ご相談ください。</p>
            </div>
          </section>

          <section>
            <h2><img src="@/assets/img/head_inquiry.svg" alt="お申し込み"></h2>
            <div class="box"><p>必ずお電話にてお申し込みください。ご希望の日時をお伺いいたします。</p>
              <p>TEL. <a class="tel" href="tel:0267425749">0267-42-5749</a></p>
              <p>FAX. 0267-42-1505</p>
            </div>
          </section>
      </main>
    <Foot/>
  </div>
</template>

<script>
import Foot from '@/components/Foot.vue'
import Nav from '@/components/Nav.vue'

export default {
  name: 'Goshinboku',
  components: {
    Foot,
    Nav
  }
}
</script>

<style lang="scss" scoped>
  p,
  ul {
      font-family: 'NuKinakoMochi-BarVtE-1'
  }
  .box {
    ul,
    p{
      margin-bottom:0;
    }
  }
  h3 {
    margin-bottom: 0;
  }
  .tel {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
  }
</style>
